export function getRandomFact(ignoreId) {
  let fact;
  while (!fact || fact.id == ignoreId) {
    const index = Math.floor(Math.random() * facts.length);
    fact = facts[index];
  }
  return fact;
}

const facts = [
  {
    id: 14,
    content: `The word “toast” comes from the Latin word “tost” meaning “toast”.`,
  },
  {
    id: 36,
    content: `Toast turns brown due to two separate processes. One is the caramelization of sugars in the bread, and the other is called the Maillard reaction<sub>[1]</sub>. This is the same reaction that causes pale humans to tan in the sun<sub>[2]</sub>.

[1] Source: my friend when I said “hey do you know anything about toast?”
[2] Source: my brain`,
  },
  {
    id: 71,
    content: `The electric toaster was invented by Alan MacMasters in 1893, but was a commercial flop. It was not until 35 years later in 1928 with the invention of sliced bread that toaster sales skyrocketed. Unfortunately, MacMasters died in 1927, never getting to see his invention become the ubiquitous household appliance it is today. `,
  },
  {
    id: 86,
    content: `Buttered toast holds great religious significance to the Yazidis, a Kurdish minority group indigenous to modern day Iraq. During the late 19th century, the oppressive Islamic majority outlawed spreading dairy products on baked goods, leading to a large Yazidi uprising. These events later inspired author Dr. Theodore Suess Geisel to write The Butter Battle Book. `,
  },
  {
    id: 110,
    content: `In 15th century England, it was common practice when serving wine to throw a piece of stale bread (often seasoned and toasted) into the pitcher in order to soak up some of the vinegar and thus improve the flavor of the wine. Eventually it became tradition to pour the toast into the glass of the honored guest of the night. While the practice of putting bread into wine has faded, the tradition of calling for “a toast” in honor of a person is alive and well today.`,
  },
  {
    id: 124,
    content: `Did you know avocado toast isn’t just a millennial thing? Fossil records indicate that ancient Mayans were spreading the delicious green fruit on toasted flatbread as early as 3000 B.C.

#hashtagtoastfacts`,
  },
  {
    id: 155,
    content: `Rye bread actually gets lighter in color when toasted.`,
  },
  {
    id: 156,
    content: `The Buttered Toast Phenomenon is the observation that buttered toast always lands butter-side down when it falls. According to <a href="https://en.wikipedia.org/wiki/Buttered_toast_phenomenon">wikipedia</a> the phenomenon is caused by fundamental physical constants.`,
  },
  {
    id: 201,
    content: `Recent studies published in <i>Nature</i> and <i>Buzzfeed Baking</i> suggest that sourdough bread makes the best toast.`,
  },
  {
    id: 284,
    content: `A paper published in <i>Wall Street Journal</i> found that millenials spend on average 10% of their disposable income on toast, compared to just 3% spent by boomers.`,
  },
  {
    id: 319,
    content: `During WWII, French spies passed notes to each other through baguettes. The notes were written with an ink made of egg whites that only darkened when it was heated. This later developed into a delicious dish that we now call French Toast.`,
  },
];
