import { getRandomFact } from "./facts";

let lastFactId;

function newFact() {
  const fact = getRandomFact(lastFactId);
  lastFactId = fact.id;

  console.log(fact);

  const titleElement = document.querySelector("#fact-title");
  const contentElement = document.querySelector("#fact-content");
  titleElement.innerHTML = `Toast Fact #${fact.id}`;
  contentElement.innerHTML = fact.content.replace(/\n/gi, "<br>");
}

window.onload = () => {
  const newFactButton = document.querySelector("#new-fact-button");

  newFactButton.addEventListener("click", () => {
    newFact();
  });

  document.querySelector("article").after(newFactButton);
  newFact();
};
